const initialStore = {
    tasks: [
        {id:1,description:"My First task",isDone:false},
        {id:2,description:"My Second task",isDone:true}
    ],
    filter:"all"
}

const rootReducer =(state = initialStore,action) =>{
    switch(action.type){
        case "FILTER_TASKS": 
        return{
            ...state,
            filter:action.payload
        }
        case "ADD_TASK" :
            return{
             ...state,
             tasks:[...state.tasks,action.payload],
            };
        case "DELETE_TASK" :
            return{
                ...state,
                tasks:state.tasks.filter((task)=>task.id !== action.payload)
            };
        case "EDIT_TASK" :
            return{
                ...state,
                tasks:state.tasks.map((task)=> 
                task.id === action.payload.id 
                ? {...task,description : action.payload.description}
                : task 
            ),
            };
        case "TOGGLE_TASK" :
            return{
                ...state,
                tasks:state.tasks.map((task) =>
                task.id === action.payload ? {...task,isDone: !task.isDone} : task)
            };
        default : return state;
    }
}


export default rootReducer